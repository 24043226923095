<template>
  <div
    :class="[
      size,
      { 'bg-anim': bgAnim},
      { 'rollover-anim': rolloverAnim},
      { 'hole': icon && icon === 'keyhole'}
    ]"
    class="circle"
  >
    <svg class="circlesvg" viewBox="0 0 80 80">
      <circle ref="circlebg" class="circle-back" cx="40" cy="40" r="30"></circle>
      <circle ref="circle"
        :style="{
          strokeDashoffset: 200 - (200 * percentage),
        }"
        class="circle-front" cx="40" cy="40" r="30"
      >
      </circle>
    </svg>
    <div v-if="bg" class="bg absolute-center"></div>
    <key class="icon absolute-center" v-if="icon && icon === 'key'"></key>
    <keyhole class="icon absolute-center" v-if="icon && icon === 'keyhole'" :style="{ opacity: 1 - percentage }"></keyhole>
    <div v-if="icon === 'circle'">
      <div class="outer-circle absolute-center" :style="{ transform: `translate(-50%, -50%) scale(${percentage})` }"></div>
      <div class="inner-circle absolute-center"></div>
    </div>
  </div>
</template>

<script>
import key from '@/assets/svg/key.svg'
import keyhole from '@/assets/svg/keyhole.svg'

export default {
  components: {
    key, keyhole
  },
  props: {
    rolloverAnim: {
      type: Boolean
    },
    bgAnim: {
      type: Boolean
    },
    percentage: {
      type: Number
    },
    bg: {
      type: Boolean
    },
    icon: {
      type: String
    },
    size: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.circle {
  position: relative;
  height: 80px;

  &.bg-anim {
    .circle-back {
      animation: dash 2s linear infinite;
    }
  }

  &.rollover-anim:hover {
    .circle-front {
      stroke-dashoffset: 0 !important;
    }
  }

  &.hole {
    height: 100px;

    svg {
      width: 100px;
      height: 100px;
    }

    svg.icon {
      width: 35px;
      height: 35px;
      position: absolute;
    }
  }

  &.mid {
    height: 60px;

    svg {
      width: 60px;
      height: 60px;
    }

    svg.icon {
      width: 25px;
      height: 25px;
      position: absolute;
    }

    .bg {
      width: 35px;
      height: 35px;
    }
  }
}

.circlesvg {
  width: 80px;
  height: 80px;
}

circle {
  stroke-width: 2px;
  fill: transparent;
  stroke-linecap: round;
}

.circle-back {
  stroke-width: 2px;
  stroke: $c-white-80;
  stroke-dasharray: 0 9;
  stroke-linecap: round;
}

.circle-front {
  stroke: $c-white;
  transition: 0.25s linear;
  stroke-dashoffset: 200;
  stroke-dasharray: 200;
}

.bg {
  width: 50px;
  height: 50px;
  border-radius: 35px;
  background: transparent radial-gradient(closest-side at 50% 50%, #fff0 0%, #ffffff27 100%) 0% 0% no-repeat padding-box;
}

.icon {
  width: 30px;
  height: 30px;
  position: absolute;
}

@keyframes dash {
  to {
    stroke-dashoffset: 60;
  }
}

.outer-circle {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  transition: 0.25s linear;
}

.inner-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
</style>
