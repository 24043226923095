<template>
  <div class="overlay-content">
    <div class="hotspot-circle back absolute-center"></div>
    <div :style="{ backgroundColor: Hotspot.overlayColor }" class="hotspot-circle front absolute-center"></div>
    <hotspot-frame v-if="Hotspot.frame" :data="Hotspot.frame.images" class="frame" />
    <div class="back-cta">
      <div class="btn">
        <div class="line"></div>
        <button-text
          :decoration="false"
          :to="{params: { contentSlug: null }}"
          tag="router-link"
          @click.native="onClose()"
        >
          {{$root.translations[$language.current].back}}
        </button-text>
        <div class="line"></div>
      </div>
    </div>
    <div :style="{ color: Hotspot.overlayColor }" class="gradient gradient-top"></div>
    <div :style="{ color: Hotspot.overlayColor }" class="gradient gradient-bottom"></div>
    <v-container fluid fill-height class="scroller">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="10" md="5" xl="4" class="content">
          <img v-if="Hotspot.mainImage" class="animcontent main-img mb-6" :src="'/media/' + Hotspot.mainImage.id">
          <video-player
            v-else-if="Hotspot.mainVideo && $vuetify.breakpoint.smAndUp"
            showPlayer
            relative
            showControl
            :content="{ videoFile: '/media/' + Hotspot.mainVideo.id}"
            class="animcontent mb-12"
          />
          <video-player
            v-else-if="Hotspot.mainVideoMobile && $vuetify.breakpoint.xs"
            showPlayer
            relative
            showControl
            :content="{ videoFile: '/media/' + Hotspot.mainVideoMobile.id}"
            class="animcontent mb-12"
          />
          <image-sequence v-else-if="Hotspot.imageSequence" :content="Hotspot.imageSequence" class="animcontent" />
          <MarkdownBlock
            v-if="Hotspot[$language.current + 'FormattedTitle'] && !Hotspot.hideTitle"
            :text="Hotspot[$language.current + 'FormattedTitle']"
            class="animcontent mb-12 hotspot-title"
            tag="h2"
            inline
          />
          <h2 v-else-if="Hotspot[$language.current + 'Title'] && !Hotspot.hideTitle" class="animcontent mb-12 hotspot-title">{{Hotspot[$language.current + 'Title']}}</h2>
          <div v-if="Hotspot.awards" class="animcontent awards mb-6">
            <img v-for="(award) in Hotspot.awards" :src="'/media/' + award.id" :key="award.id">
          </div>
          <MarkdownBlock
            v-if="Hotspot[$language.current + 'Description']"
            :text="Hotspot[$language.current + 'Description']"
            class="animcontent"
          />
          <div class="animcontent my-9 hotspot-urls">
            <a
              v-if="Hotspot.recipePDF && $root.embedMode === '1' && !$root.isLotteApp"
              :href="`/media/${Hotspot.recipePDF.id}`"
              :download="`${Hotspot.title} Recipe`"
              @click="$root.pushAnalyticsEvent('click_on_download_recipe', 'Hotspot', 'click', `${Hotspot.enTitle}`, '')"
              class="hotspot-cta"
            >
              <CircleSVG bg rolloverAnim />
              <DownloadSvg class="absolute-center" />
              <small>{{$root.translations[$language.current].recipe}}</small>
            </a>
            <component
              v-if="ProductShopUrl"
              :is="ProductShopUrl && ProductShopUrlAvailable ? 'a' : 'button'"
              :href="ProductShopUrlAvailable ? ProductShopUrl : null"
              :target="ProductShopUrlAvailable ? '_blank' : null"
              @click="trackProductShopClick()"
              class="hotspot-cta"
            >
              <CircleSVG bg rolloverAnim />
              <component :is="ProductShopUrlAvailable ? 'ShopNowSvg' : 'ShopSoonSvg'" class="absolute-center" />
              <small>{{$root.translations[$language.current][`${ProductShopUrlAvailable ? 'buyNow' : 'availableSoon'}`]}}</small>
            </component>
            <a
              v-if="ProductDiscoverUrl"
              :href="ProductDiscoverUrl"
              target="'_blank'"
              class="hotspot-cta"
            >
              <CircleSVG bg rolloverAnim />
              <component :is="'ShopNowSvg'" class="absolute-center" />
            <small>{{$root.translations[$language.current].discoverMore || $root.translations.en.discoverMore}}</small>
          </a>
          </div>
          <div v-if="Hotspot.gallery" class="my-16 gallery animcontent">
            <Gallery :slides="Hotspot.gallery" />
          </div>
          <div v-if="Hotspot.video" class="my-16 animcontent">
            <video-player showPlayer relative showControl :content="{ videoFile: '/media/' + Hotspot.video.id}" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HotspotFrame from '../atoms/HotspotFrame.vue'
import { gsap } from 'gsap'
import VideoPlayer from '@/atoms/VideoPlayer.vue'
import ImageSequence from '@/molecules/ImageSequence.vue'
import MarkdownBlock from '@/atoms/MarkdownBlock.vue'
import Gallery from '@/molecules/Gallery.vue'

import CircleSVG from '@/atoms/CircleSVG.vue'
import DownloadSvg from '@/assets/svg/download.svg'
import ShopNowSvg from '@/assets/svg/shop-now.svg'
import ShopSoonSvg from '@/assets/svg/soon.svg'
import ButtonText from '@/atoms/ButtonText.vue'

export default {
  name: 'OverlayContent',
  props: {
    Hotspot: { type: Object }
  },
  components: { VideoPlayer, ImageSequence, MarkdownBlock, Gallery, HotspotFrame, CircleSVG, DownloadSvg, ShopNowSvg, ShopSoonSvg, ButtonText },
  beforeDestroy () {
    this.$root.productDiscalimerText = null
    this.tl && this.tl.kill()
  },
  async mounted () {
    await this.$nextTick()
    this.createTimeline()
    this.$root.productDiscalimerText = this.Hotspot[this.$language.current + 'DisclaimerText'] || this.Hotspot.enDisclaimerText
  },
  computed: {
    ProductDiscoverUrl () {
      return this.Hotspot[`${this.$language.current}DiscoverURL${this.$root.embedMode}`]
    },
    ProductShopUrl () {
      return this.Hotspot[`${this.$language.current}ShopURL${this.$root.embedMode}`]
    },
    ProductShopUrlAvailable () {
      return !this.ProductShopUrl.includes('soon')
    }
  },
  methods: {
    async createTimeline () {
      await this.$nextTick()
      this.tl = gsap.timeline()
      this.tl.addLabel('fade-out')
      this.tl.fromTo(this.$el, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, ease: 'power2.in' })
      this.tl.fromTo(['.hotspot-circle.back', '.hotspot-circle.front'],
        { scale: 0 },
        { scale: 1.4, duration: 1.5, stagger: 0.5, ease: 'power2.in' },
        '-=0.5'
      )
      this.tl.addLabel('fade-in')
      this.tl.fromTo(
        this.$el.querySelectorAll(['.gradient']),
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.5 },
        '-=0.25'
      )
      this.tl.fromTo('.frame .image',
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.5 },
        '-=0.25'
      )
      this.tl.fromTo('.animcontent',
        { autoAlpha: 0, y: 20 },
        { autoAlpha: 1, y: 0, duration: 0.5, stagger: 0.2 },
        '-=0.25'
      )
      this.tl.fromTo('.back-cta',
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.25, ease: 'power2.in' }
      )
    },
    trackProductShopClick () {
      if (!this.ProductShopUrlAvailable) return
      this.$root.pushAnalyticsEvent('click_on_buy_now', 'Hotspot', 'click', `${this.Hotspot.enTitle}`, '')
    },
    onClose () {
      const fromPosition = this.tl.time() < 2 ? this.tl.time() : 'fade-in'
      this.tl && this.tl.tweenFromTo(fromPosition, 'fade-out')
    }
  },
  watch: {
    '$language.current' (locale) {
      this.$root.productDiscalimerText = this.Hotspot[locale + 'DisclaimerText'] || this.Hotspot.enDisclaimerText
    }
  }
}
</script>

<style lang="scss" scoped>
/* hide scrollbar but allow scrolling */
.scroller {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.scroller::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.hotspot-circle {
  width: 200vw;
  height: 200vw;
  border-radius: 100%;
  position: fixed !important;

  &.back {
    background-color: rgba(172, 132, 60, 0.4);
    z-index: 0;
  }

  &.front {
    opacity: 0.4;
    z-index: 1;
  }
}

.overlay-content {
  position: fixed;
  z-index: $z-overlay-content;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.gradient {
  position: fixed;
  left: 0;
  right: 0;
  z-index: $z-gradient;
  height: 200px;
  pointer-events: none;

  @include breakpoint ('xs-only') {
    height: 180px;
  }
}

.gradient-top {
  top: 0;
  opacity: 0.75;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 70%);

  @include breakpoint ('sm-and-down') {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, currentColor 70%);
    filter: brightness(0.3);
  }
}

.gradient-bottom {
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 70%);
  opacity: 0.75;

  @include breakpoint ('sm-and-down') {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, currentColor 70%);
    filter: brightness(0.3);
  }
}

.scroller {
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 200px 22px;
  overflow-y: auto;
  overflow-x: hidden;
}

.content {
  // width: 50%;
  // margin: 0 auto;
  text-align: center;
  position: relative;

  // @include breakpoint('md-and-down') {
  //   width: 70%;
  // }

  // @include breakpoint('sm-and-down') {
  //   width: 90%;
  // }

  .main-img {
    width: 100%;
    height: 40vh;
    margin: 50px 0;
    object-fit: contain;

    @include breakpoint ('sm-and-down') {
      height: 30vh;
    }
  }

  .hotspot-title {
    position: relative;
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      width: 50px;
      height: 1px;
      background: $c-gold;
      left: 50%;
      bottom: -24px;
      transform: translateX(-50%);
    }
  }
}

.awards {
  img {
    display: inline-block;
    width: 70px;
    height: auto;
    margin: 10px;
  }
}

/* stylelint-disable */
.back-cta {
  width: 100%;
  max-width: 275px;
  height: 75px;
  position: fixed;
  bottom: 20px;
  right: 50%;
  transform: translateX(50%);
  z-index: $z-ui-el;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint ('sm-and-down') {
    bottom: 100px;
  }

  html[lang='tw'] & {
    bottom: 80px;

    @include breakpoint ('sm-and-down') {
      bottom: 135px;
    }
  }

  .btn {
    cursor: pointer;

    &:hover {
      .line {
        opacity: 1;
      }

      ::v-deep .light {
        width: 100%;
      }
    }
  }

  ::v-deep .button-text {
    display: inline-block;
    vertical-align: middle;
    margin: 0 12px;
    text-transform: uppercase;
    font: 18px/24px futura-pt;
    letter-spacing: 2.8px;

    .original,
    .dark,
    .light,
    p {
      font: 18px/24px futura-pt;
    }
  }

  .line {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 1px;
    background-color: $c-gold;
    opacity: 0.5;
    transition: opacity 0.3s;
  }
}
/* stylelint-enable */

.hotspot-urls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 44px;

  @include breakpoint ('sm-and-up') {
    flex-direction: row;
    gap: 72px;
  }
}

.hotspot-cta {
  position: relative;
  display: inline-block;
  pointer-events: all;

  transform: scale(1.2);

  // &:hover small {
  //   @include breakpoint ('md-and-up') {
  //     opacity: 1;
  //     bottom: -20%;
  //     transform: translate(50%, 0) scale(1);
  //     letter-spacing: 2.8px;
  //   }
  // }

  svg {
    pointer-events: none;
    position: absolute;
    width: 20px;
    height: 20px;
  }

  small {
    opacity: 1;
    bottom: -20%;
    transform: translate(50%, 0) scale(1);
    letter-spacing: 2.8px;
    letter-spacing: 2.8px;
    text-transform: uppercase;
    font: 12px/16px futura-pt;
    min-width: 100%;
    display: inline-block;
    color: $c-white;
    position: absolute;
    bottom: -20%;
    right: 50%;
    transform: translate(50%, 0);
    pointer-events: none;
    white-space: nowrap;
  }
}
</style>
