<template>
  <div :class="{ blending }" class="renderer">
    <slot/>
  </div>
</template>

<script>
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer'
import Viewport from '@monogrid/vue-lib/lib/mixins/Viewport'
import { Scene } from 'three'

export default {
  name: 'PanoSceneCss3d',
  props: {
    blending: { type: Boolean }
  },
  mixins: [Viewport],
  inject: ['viewer'],
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.scene = new Scene()
      this.renderer = new CSS3DRenderer({ element: this.$el })
      this.renderer.setSize(this.$el.clientWidth, this.$el.clientHeight)
      this.viewer.css.renderer = this.renderer
      this.viewer.css.scene = this.scene
    }
  }
}
</script>

<style lang="scss" scoped>
.renderer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: transparent;
  pointer-events: none;

  &.blending {
    mix-blend-mode: lighten;
  }
}
</style>
