<template>
  <div>
    <canvas ref="canvas" oncontextmenu="return false;"/>
    <slot name="controls"/>
    <transition-group
      :tag="'div'"
      :duration="transitionDuration"
      :css="false"
      @before-leave="beforeLeave"
    >
      <slot />
    </transition-group>
    <portal-target name="pano-debug" multiple class="pano-debug"/>
  </div>
</template>

<script>
import * as THREE from 'three'
import { gsap } from 'gsap'
import RAF from '@monogrid/js-utils/lib/RAF'
import Viewport from '@monogrid/vue-lib/lib/mixins/Viewport'

export default {
  name: 'PanoViewer',
  mixins: [Viewport],
  provide () {
    return {
      viewer: this.viewer
    }
  },
  props: {
    transitionDuration: { type: Number, default: 1000 },
    css3d: { type: Boolean, default: false }
  },
  data () {
    return {
      viewer: {
        debug: false,
        debugObserved: new Map(),
        camera: null,
        controls: null,
        css: {
          observed: new Map(),
          renderer: null,
          scene: null
        },
        webgl: {
          observed: new Map(),
          renderer: null,
          scene: null
        }
      }
    }
  },
  mounted () {
    this.init()
    window.addEventListener('mousemove', this.onMouseMove, true)

    if (this.$root.process.env.SERVICE_STAGE !== 'production') {
      window.addEventListener('keydown', this.onKeyDown, true)
      window.addEventListener('keyup', this.onKeyUp, true)
    }

    RAF.add(this.onRAF)
  },
  beforeDestroy () {
    if (this.$root.process.env.SERVICE_STAGE !== 'production') {
      window.removeEventListener('keydown', this.onKeyDown, true)
      window.removeEventListener('keyup', this.onKeyUp, true)
    }

    RAF.remove(this.onRAF)
  },
  watch: {
    viewPort () { this.onResize() }
  },
  methods: {
    async beforeLeave (element) {
      const component = element.__vue__
      component.leave && await component.leave()
    },
    init () {
      THREE.Cache.enabled = true

      this.renderer = new THREE.WebGLRenderer({ canvas: this.$refs.canvas, alpha: true, antialias: false })
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
      this.camera = new THREE.PerspectiveCamera(this.viewPort.width < 600 ? 90 : 70, this.$el.clientWidth / this.$el.clientHeight, 1, 10000)
      this.scene = new THREE.Scene()

      this.viewer.camera = this.camera
      this.viewer.webgl.renderer = this.renderer
      this.viewer.webgl.scene = this.scene
    },
    setCameraFov (fov, duration) {
      if (!duration) {
        this.camera.fov = fov
        this.camera.updateProjectionMatrix()
      } else {
        gsap.to(this.camera, {
          fov,
          duration,
          onUpdate (camera) {
            camera.updateProjectionMatrix()
          },
          onUpdateParams: [this.camera]
        })
      }
    },
    onKeyDown (event) {
      this.viewer.debugObserved.forEach(({ name, key, code, toggle }) => {
        if ((key && key === event.key) || (code && code === event.code)) {
          if (toggle) {
            this.viewer.debug = this.viewer.debug !== name ? name : null
          } else {
            this.viewer.debug = name
          }
        }
      })
    },
    onKeyUp (event) {
      this.viewer.debugObserved.forEach(({ key, code, toggle }) => {
        if (!toggle) {
          if ((key && key === event.key) || (code && code === event.code)) {
            this.viewer.debug = null
          }
        }
      })
    },
    onResize () {
      this.camera.aspect = this.$el.clientWidth / this.$el.clientHeight
      this.camera.updateProjectionMatrix()

      this.renderer.setSize(this.$el.clientWidth, this.$el.clientHeight)
      this.viewer.css.renderer && this.viewer.css.renderer.setSize(this.$el.clientWidth, this.$el.clientHeight)
    },
    onRAF () {
      const now = Date.now()
      const deltaTime = Math.min(0.050, (now - (this.time || now)) / 1000)
      this.time = now

      this.viewer.controls && this.viewer.controls.onRAF(deltaTime)

      this.renderer.render(this.scene, this.camera)
      this.viewer.css.renderer && this.viewer.css.renderer.render(this.viewer.css.scene, this.camera)

      this.viewer.webgl.observed.forEach(obj => {
        obj.onRAF && obj.onRAF(deltaTime)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pano-debug {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding: rem(100px 0);

  pointer-events: none;
}
</style>
