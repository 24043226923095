<script>
import { Vector3, Raycaster } from 'three'
import ElasticNumber from '@monogrid/js-utils/src/ElasticNumber'
import { gsap } from 'gsap'

const radians = Math.PI / 180
const degToRad = degree => degree * radians
const modulate = (val, max = 360) => {
  const newValue = val % max
  return Math.abs(newValue < 0 ? max + newValue : newValue)
}

export default {
  name: 'PanoControls',
  inject: ['viewer'],
  props: {
    prevent: { type: Boolean, default: true },
    threshold: { type: Number, default: 1 },
    touchFactor: { type: Number, default: 2.5 },
    mouseFactor: { type: Number, default: 1 },
    minYAngle: { type: Number, default: -45 },
    maxYAngle: { type: Number, default: 45 },
    minXAngle: { type: Number, default: -Infinity },
    maxXAngle: { type: Number, default: Infinity }
  },
  created () {
    this.type = 'controls'
    this.lat = new ElasticNumber(0)
    this.lon = new ElasticNumber(0)

    this.lat.speed = 10
    this.lon.speed = 10
  },
  beforeDestroy () {
    if (!this.added) return
    this.viewer.controls = null
    this.toggleListeners('remove')
    this.toggleListeners('remove', 'debug')
    if (!this.viewer.debugObserved.has(this.type + 'LatLon')) {
      this.viewer.debugObserved.delete(this.type + 'LatLon')
      this.viewer.debugObserved.delete(this.type + 'Intersect')
    }
  },
  watch: {
    'viewer.webgl.scene': {
      immediate: true,
      handler () {
        if (!this.added && this.viewer.webgl.renderer) {
          this.init()
        }
      }
    },
    'viewer.debug': {
      immediate: true,
      handler (value) {
        if (this.viewer.webgl.renderer) {
          this.toggleListeners(value && value.indexOf(this.type) > -1 ? 'add' : 'remove', 'debug')
        }
        if (!value) {
          this._lastLat = null
          this._lastLon = null
        }
      }
    }
  },
  methods: {
    init () {
      if (this.added) return
      this.added = true
      // position we focus on
      this.lookat = new Vector3()

      this.raycaster = new Raycaster()
      this.mouse = new Vector3()

      this.toggleListeners('add', 'start')
      this.viewer.controls = this

      if (!this.viewer.debugObserved.has(this.type + 'LatLon')) {
        this.viewer.debugObserved.set(this.type + 'LatLon', {
          name: this.type + 'LatLon',
          key: 'Control',
          toggle: false
        })

        this.viewer.debugObserved.set(this.type + 'Intersect', {
          name: this.type + 'Intersect',
          code: 'KeyI',
          toggle: false
        })
      }
    },
    checkBounds () {
      // let phi = degToRad(90 - this.lat.target)
      // phi = Math.max(this.minYAngle, Math.min(this.maxYAngle, phi))
      // this.lat.target = 90 - (phi / radians)
      this.lat.target = Math.max(this.minYAngle, Math.min(this.maxYAngle, this.lat.target))
      this.lon.target = Math.max(this.minXAngle, Math.min(this.maxXAngle, this.lon.target))
    },
    debugLatLon () {
      this._currentLat = this.lat.value.toFixed(3)
      this._currentLon = this.lon.value.toFixed(3)
      if (this._currentLat !== this._lastLat || this._currentLon !== this._lastLon) {
        console.log('[Controls] lat,lon -> ', this._currentLat, this._currentLon)
        // eslint-disable-next-line
        navigator.clipboard.writeText(`${this._currentLat},${this._currentLon}`).then(function () {
          console.log('[Controls] lat,lon -> Copied to clipboard!')
        }, function (err) {
          console.log('[Controls] lat,lon -> Not copies, ', err)
        })

        this._lastLat = this._currentLat
        this._lastLon = this._currentLon
      }
    },
    debugIntersect () {
      this.raycaster.setFromCamera(this.mouse, this.viewer.camera)
      if (this.mouse.x === this._lastX && this.mouse.y === this._lastY) {
        return
      }
      this._lastX = this.mouse.x
      this._lastY = this.mouse.y

      this.viewer.webgl.observed.forEach(obj => {
        const intersects = this.raycaster.intersectObject(obj.mesh, true)
        if (intersects.length > 0) {
          const point = intersects[0].point.clone()
          const world = obj.mesh.getWorldPosition(new Vector3())
          point.sub(world)

          if (point.length() > 0) {
            console.log(`[Controls] intersect ${obj.type} : ${point.x.toFixed(2)},${point.y.toFixed(2)},${point.z.toFixed(2)}`)

            // eslint-disable-next-line
              navigator.clipboard.writeText(`${point.x.toFixed(2)},${point.y.toFixed(2)},${point.z.toFixed(2)}`).then(function () {
              console.log('[Controls] intersect -> Copied to clipboard!')
            }, function (err) {
              console.log('[Controls] intersect -> Not copies, ', err)
            })
          }
        }
      })
    },
    lookAtLatLon (_lat, _lon, duration = false, resetLatitude = false) {
      const lat = resetLatitude ? 1 : Number(_lat)
      let lon = Number(_lon)

      if (duration === false) {
        this.lat.target = lat
        this.lon.target = lon
        this.checkBounds()
      } else if (duration === 0) {
        this.lat.target = this.lat.value = lat
        this.lon.target = this.lon.value = lon
      } else {
        lon = modulate(lon)
        this.lat.target = this.lat.value
        this.lon.target = modulate(this.lon.value)
        const diff = this.lon.target - lon

        if (diff > 180) {
          if (lon - this.lon.target < 0) {
            lon = 360 + lon
          }
        } else if (diff < -180) {
          if (this.lon.target - lon < 0) {
            lon = -(360 - lon)
          }
        }

        gsap.to(
          { lat: this.lat.target, lon: this.lon.target },
          {
            lat,
            lon,
            duration,
            onStart: () => {
              this.autoPan = true
            },
            onUpdate (lat, lon) {
              const [target] = this.targets()
              lat.target = lat.value = target.lat
              lon.target = lon.value = target.lon
            },
            onUpdateParams: [this.lat, this.lon],
            onComplete: () => {
              this.autoPan = false
            }
          }
        )
      }
    },
    lookAt (x, y, z, duration = false, resetLatitude = false) {
      const radius = 5000
      const lat = 90 - Math.acos(Number(y) / radius) * (180 / Math.PI)
      const lon = Math.atan2(Number(z), Number(x)) * (180 / Math.PI)

      this.lookAtLatLon(lat, lon, duration, resetLatitude)
    },
    resetLatitude (duration = 2) {
      // this.lat.target = 0
      this.lookAtLatLon(0, this.lon.target, duration)
    },
    onDragStart (e) {
      this.preventEvent(e)
      this.isDragging = true
      const target = e.touches ? e.touches[0] : e
      this.lastX = this.startX = target.clientX
      this.lastY = this.startY = target.clientY

      this.startLon = this.lon.target
      this.startLat = this.lat.target

      this.toggleListeners('add', 'move')

      this.$emit('drag-state-change', this.isDragging)
      this.$events.emit('drag-start')
    },
    onDragEnd (e) {
      this.preventEvent(e)
      this.isDragging = false

      this.lastX = this.startX = null
      this.lastY = this.startY = null

      this.toggleListeners('remove', 'move')

      this.$emit('drag-state-change', this.isDragging)
    },
    onDrag (e) {
      if (!this.isDragging) return
      this.preventEvent(e)

      const target = e.touches ? e.touches[0] : e
      const deltaX = (this.startX - target.clientX) * (e.touches ? this.touchFactor : this.mouseFactor)
      const deltaY = (target.clientY - this.startY) * (e.touches ? this.touchFactor : this.mouseFactor)

      if (deltaX !== 0 || deltaY !== 0) {
        this.lon.target = (deltaX) * 0.1 + this.startLon
        this.lat.target = (deltaY) * 0.1 + this.startLat
        this.lat.target = Math.max(-85, Math.min(85, this.lat.target))
        this.checkBounds()
        this.updateCamera()
      }
      this.lastX = target.clientX
      this.lastY = target.clientY
    },
    onMouseMove (e) {
      if (!this.viewer.webgl.renderer) return
      const rect = this.viewer.webgl.renderer.domElement.getBoundingClientRect()
      this.mouse.set(
        ((e.clientX - rect.left) / rect.width) * 2 - 1,
        -((e.clientY - rect.top) / rect.height) * 2 + 1
      )
    },
    onMouseLeave (e) {
      const from = e.relatedTarget || e.toElement
      if (!from || from.nodeName === 'HTML') {
        this.onDragEnd(e)
      }
    },
    onRAF (deltaTime) {
      if (this.autoPan !== true) {
        this.lat.update(deltaTime)
        this.lon.update(deltaTime)
      }
      this.updateCamera()

      if (this.viewer.debug === this.type + 'LatLon') {
        this.debugLatLon()
      }

      if (this.viewer.debug === this.type + 'Intersect') {
        this.debugIntersect()
      }
    },
    preventEvent (e) {
      if (this.prevent) {
        e.preventDefault()
        e.stopPropagation()
      }
    },
    toggleListeners (mode, stage = 'all') {
      const el = this.viewer.webgl.renderer.domElement
      const method = (mode === 'add' ? 'add' : 'remove') + 'EventListener'
      const opts = { passive: !this.prevent }

      if (stage === 'debug') {
        window[method]('mousemove', this.onMouseMove, opts)
        return
      }

      if (stage !== 'move') {
        el[method]('touchstart', this.onDragStart, opts)
        el[method]('mousedown', this.onDragStart, opts)

        window.addEventListener('keydown', this.onKeyDown, opts)
        window.addEventListener('keyup', this.onKeyUp, opts)
      }
      if (stage !== 'start') {
        window[method]('touchmove', this.onDrag, opts)
        window[method]('mousemove', this.onDrag, opts)
        window[method]('touchend', this.onDragEnd, opts)
        window[method]('mouseup', this.onDragEnd, opts)
        window[method]('mouseleave', this.onMouseLeave, opts)
        window[method]('mouseout', this.onMouseLeave, opts)
      }
    },
    updateCamera () {
      const phi = degToRad(90 - this.lat.value)
      const theta = degToRad(this.lon.value)

      this.lookat.set(
        5000 * Math.sin(phi) * Math.cos(theta),
        5000 * Math.cos(phi),
        5000 * Math.sin(phi) * Math.sin(theta)
      )
      this.viewer.camera.lookAt(this.lookat)
    }
  },
  render () {
    return null
  }
}
</script>
