import { render, staticRenderFns } from "./HotspotFrame.vue?vue&type=template&id=37a11cec&scoped=true&"
import script from "./HotspotFrame.vue?vue&type=script&lang=js&"
export * from "./HotspotFrame.vue?vue&type=script&lang=js&"
import style0 from "./HotspotFrame.vue?vue&type=style&index=0&id=37a11cec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a11cec",
  null
  
)

export default component.exports