<template>
  <router-link alt="" title="" :to="{ params: { contentSlug: _slug } }" @click.native="onClick()" class="hotspot">
    <span class="pulse"></span>
    <CircleSVG bg bgAnim rolloverAnim />
    <PlusSvg :class="type" />
  </router-link>
</template>

<script>
import CircleSVG from './CircleSVG.vue'
import PlusSvg from '@/assets/svg/plus.svg'

export default {
  name: 'HotspotHtml',
  components: { PlusSvg, CircleSVG },
  props: {
    _slug: { type: String },
    type: { type: String }
  },
  methods: {
    onClick () {
      //
    }
  }
}
</script>

<style lang="scss" scoped>
.hotspot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: rem(80px);
  height: rem(80px);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    pointer-events: none;

    &.Storytelling path {
      fill: $c-blue;
    }

    &.Product path {
      fill: $c-gold;
    }

    &.Multimedia path {
      fill: $c-bronze;
    }
  }
}

::v-deep .bg {
  background: transparent radial-gradient(closest-side at 50% 50%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.35) 100%) 0% 0% no-repeat padding-box;
}

.pulse {
  display: block;
  height: 100px;
  width: 100px;
  background-color: $c-white;
  box-shadow: 0 0 0 $c-white;
  border-radius: 90px;
  animation: pulse 2.4s ease-in-out infinite;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0;
    box-shadow: 0 0 0 $c-white;
  }

  60% {
    opacity: 0.4;
    box-shadow: 0 0 40px $c-white;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    box-shadow: 0 0 10px $c-white;
  }
}
</style>
