<template>
  <div>
    <div :class="{ visibile: hotspotVisible }" class="gradient gradient-top"></div>
    <div :class="{ visibile: hotspotVisible }" class="gradient gradient-bottom"></div>
    <PanoViewer ref="viewer" class="panorama">
      <template v-slot:controls>
        <PanoControls
          ref="controls"
          key="controls"
          :minYAngle="-25"
          :maxYAngle="25"
          :minXAngle="(cameraLeftLimit || -Infinity)"
          :maxXAngle="(cameraRightLimit || Infinity)"
          @drag-state-change="($event) => { isDragging = $event }"
        />
        <PanoSceneCss3d key="css3d" />
      </template>
      <PanoImage
        v-if="panoImage"
        :teleport="false"
        :transition-time="1"
        :key="roomSlug"
        :src="panoImage"
        :initial-camera="panoInitialCamera"
        @enter-fade-end="panoramaVisible = true"
      >
        <PanoHotspot
          v-for="(hotspot, i) in hotspots"
          :key="roomSlug + '' + i"
          :show="hotspotVisible"
          :position="hotspot.position"
        >
          <HotspotHtml v-bind="hotspot.contentId" @click.native="hotspotChange(hotspot, true)" />
        </PanoHotspot>
      </PanoImage>
    </PanoViewer>
    <v-fade-transition mode="out-in">
      <OverlayContent v-if="currentHotspot" :key="currentHotspot.contentId._slug" :Hotspot="currentHotspot.contentId" />
    </v-fade-transition>
  </div>
</template>

<script>
import RoomQuery from '@/graphql/GetRoom.gql'

import PanoViewer from '@/3d/panolens/PanoViewer.vue'
import PanoControls from '@/3d/panolens/PanoControls.vue'
import PanoImage from '@/3d/panolens/PanoImage.vue'
import PanoHotspot from '@/3d/panolens/PanoHotspot.vue'
import PanoSceneCss3d from '@/3d/panolens/PanoSceneCss3d.vue'
import OverlayContent from '@/organisms/OverlayContent.vue'

import HotspotHtml from '@/atoms/HotspotHtml.vue'

export default {
  name: 'RoomPage',
  props: {
    roomSlug: { type: String },
    contentSlug: { type: String }
  },
  apollo: {
    Room: {
      query: RoomQuery,
      variables () {
        return {
          status: 'PUBLISHED',
          slug: this.roomSlug
        }
      }
    }
  },
  components: { PanoViewer, PanoImage, PanoControls, PanoHotspot, PanoSceneCss3d, HotspotHtml, OverlayContent },
  data () {
    return {
      panoramaVisible: false,
      cameraFovDefault: 70,
      cameraZoomDefault: 45
    }
  },
  computed: {
    currentRoom () {
      return this.Room && (this.roomSlug === this.Room._slug) ? this.Room : null
    },
    panoImage () {
      if (!this.currentRoom) return
      if (this.$root.embedMode && this.currentRoom[`image360Embed${this.$root.embedMode}`]) {
        return `/media/${this.currentRoom[`image360Embed${this.$root.embedMode}`].id}`
      }
      return `/media/${this.currentRoom.image360.id}`
    },
    panoInitialCamera () {
      if (!this.currentRoom) return
      if (this.$vuetify.breakpoint.mdAndUp) {
        if (this.$root.embedMode && this.currentRoom['initialPoint' + this.$root.embedMode]) {
          return this.currentRoom['initialPoint' + this.$root.embedMode]
        } else {
          return this.currentRoom.initialPoint
        }
      } else {
        if (this.$root.embedMode && this.currentRoom['initialPointMobile' + this.$root.embedMode]) {
          return this.currentRoom['initialPoint' + this.$root.embedMode]
        } else {
          return this.currentRoom.initialPointMobile
        }
      }
    },
    cameraLeftLimit () {
      if (!this.currentRoom) return
      if (this.$vuetify.breakpoint.mdAndUp) {
        if (this.$root.embedMode && this.currentRoom['minXAngle' + this.$root.embedMode]) {
          return this.currentRoom['minXAngle' + this.$root.embedMode]
        } else {
          return this.currentRoom.minXAngle
        }
      } else {
        if (this.$root.embedMode && this.currentRoom['minXAngleMobile' + this.$root.embedMode]) {
          return this.currentRoom['minXAngleMobile' + this.$root.embedMode]
        } else {
          return this.currentRoom.minXAngleMobile
        }
      }
    },
    cameraRightLimit () {
      if (!this.currentRoom) return
      if (this.$vuetify.breakpoint.mdAndUp) {
        if (this.$root.embedMode && this.currentRoom['maxXAngle' + this.$root.embedMode]) {
          return this.currentRoom['maxXAngle' + this.$root.embedMode]
        } else {
          return this.currentRoom.maxXAngle
        }
      } else {
        if (this.$root.embedMode && this.currentRoom['maxXAngleMobile' + this.$root.embedMode]) {
          return this.currentRoom['maxXAngleMobile' + this.$root.embedMode]
        } else {
          return this.currentRoom.maxXAngleMobile
        }
      }
    },
    hotspots () {
      if (!this.currentRoom?.hotspot?.length) return []
      return this.currentRoom.hotspot.filter((h) => {
        if (!h.contentId.visibleOnlyEmbedModes) return true
        if (h.contentId.visibleOnlyEmbedModes.includes(this.$root.embedMode)) return true
        return false
      })
    },
    currentHotspot () {
      return this.hotspots?.filter(h => h.contentId._slug === this.contentSlug)[0]
    },
    hotspotVisible () {
      return this.panoramaVisible && !this.contentSlug
    }
  },
  methods: {
    async hotspotChange (hotspot, trackEvent = false) {
      await this.$nextTick()

      if (hotspot && trackEvent) {
        this.$root.pushAnalyticsEvent('click_on_hotspot', 'Hotspot', 'click', `${hotspot.contentId.enTitle}`, '')
      }
    },
    trackPageView () {
      if (this.currentRoom && this.$root.useAnalytics && this.$gtag) {
        this.$gtag.pageview({
          page_title: this.currentRoom.enTitle || this.currentRoom.title + ' | Royal Salute',
          page_path: window.location.pathname,
          page_location: window.location.href,
          send_page_view: false
        })
      }
    },
    trackHotspotPageView () {
      if (this.currentHotspot && this.$root.useAnalytics && this.$gtag) {
        this.$gtag.pageview({
          page_title: this.currentHotspot.contentId.enTitle || this.currentHotspot.contentId.title + ' | Royal Salute',
          page_path: window.location.pathname,
          page_location: window.location.href,
          send_page_view: false
        })
      }
    }
  },
  watch: {
    currentRoom (room) {
      this.trackPageView()
      room?.audio
        ? this.$events.$emit('page-has-audio', room.audio.id)
        : this.$events.$emit('page-has-no-audio')
    },
    currentHotspot (hotspot) {
      const zoom = hotspot ? (hotspot.contentId.cameraZoomValue || this.cameraZoomDefault) : this.cameraFovDefault
      const position = hotspot ? hotspot.position : null

      if (this.$refs.viewer) {
        this.$refs.viewer.setCameraFov(zoom, 1)
      }

      if (this.$refs.controls && position) {
        this.$refs.controls.lookAt(...position.split(','), 1)
      }

      this.trackHotspotPageView()
    },
    '$root.useAnalytics' (newValue, oldValue) {
      if (!oldValue && newValue) {
        this.trackPageView()
      }
    }
  },
  metaInfo () {
    let title = 'Room Page'
    if (this.currentHotspot) {
      title = this.currentHotspot.contentId.enTitle
    } else if (this.currentRoom) {
      title = this.currentRoom.enTitle
    }
    return {
      title
    }
  }
}
</script>

<style lang="scss" scoped>
.gradient {
  pointer-events: none;
  position: fixed;
  left: 0;
  right: 0;
  z-index: $z-gradient;
  height: 200px;
  opacity: 0;
  transition: opacity 0.5s ease-out;

  @include breakpoint ('sm-and-down') {
    height: 150px;
  }

  &.visibile {
    opacity: 1;
  }

  &.gradient-top {
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 75%);
  }

  &.gradient-bottom {
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 75%);
  }
}

.panorama {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transition: opacity 0.4s 0.3s;
}
</style>
