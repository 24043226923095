<template>
  <div class="wrapper" :class="{visible: show && visible}" draggable="false">
    <slot />
  </div>
</template>

<script>
import {
  SpriteMaterial,
  Sprite,
  Frustum,
  Matrix4,
  Vector3
} from 'three'
import { gsap } from 'gsap'

export default {
  name: 'PanoHotspot',
  inject: ['viewer'],
  props: {
    position: { type: String },
    scale: { type: Number, default: 500 },
    show: { type: Boolean, default: false }
  },
  data () {
    return {
      x: 0,
      y: 0,
      visible: false
    }
  },
  created () {
    this.type = 'hotspot'
    this.material = new SpriteMaterial({
      opacity: 0,
      transparent: true,
      depthWrite: false,
      depthTest: false
    })
    this.mesh = new Sprite(this.material)

    this.cameraFrustum = new Frustum()
    this.cameraViewProjectionMatrix = new Matrix4()

    this.mesh.scale.set(this.scale, this.scale, 1)
    this.mesh.position.set(...this.position.split(','))
    this.mesh.frustumCulled = false
    this.mesh.rotation.y = Math.PI
    this.addToScene()
  },
  watch: {
    'viewer.webgl.scene': {
      immediate: true,
      handler () { this.addToScene() }
    }
  },
  beforeDestroy () {
    if (!this.added) return

    this.viewer.webgl.observed && this.viewer.webgl.observed.delete(this)
    this.mesh.geometry.dispose()
    this.mesh.material.dispose()
    this.viewer.webgl.scene.remove(this.mesh)
  },
  methods: {
    addToScene () {
      if (this.viewer.webgl.scene && this.mesh && !this.added) {
        this.added = true
        this.viewer.webgl.scene.add(this.mesh)
        this.viewer.webgl.observed.set(this, this)
      }
    },
    checkSpriteInViewport () {
      this.cameraViewProjectionMatrix.multiplyMatrices(this.viewer.camera.projectionMatrix, this.viewer.camera.matrixWorldInverse)
      this.cameraFrustum.setFromProjectionMatrix(this.cameraViewProjectionMatrix)

      return this.mesh.visible && this.cameraFrustum.intersectsSprite(this.mesh)
    },
    getScreenVector (vector) {
      const el = this.viewer.webgl.renderer.domElement

      const widthHalf = el.clientWidth / 2
      const heightHalf = el.clientHeight / 2

      vector.project(this.viewer.camera)

      vector.x = (vector.x * widthHalf) + widthHalf
      vector.y = -(vector.y * heightHalf) + heightHalf
      vector.z = 0

      return vector
    },
    onRAF () {
      if (this.checkSpriteInViewport(this.mesh)) {
        const { x, y } = this.getScreenVector(this.mesh.getWorldPosition(new Vector3()))
        this.translate(x, y)
        this.toggleVisibility(true)
      } else {
        this.toggleVisibility(false)
      }
    },
    toggleVisibility (visibility) {
      this.visible = visibility
    },
    translate (x, y) {
      gsap.set(this.$el, { x, y })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;
  transition: opacity 0.2s;

  &.visible {
    opacity: 1;
  }
}
</style>
