<template>
  <section>
    <div ref="swiper" class="swiper-container">
      <div ref="swiperWrapper" class="swiper-wrapper">
        <article ref="slides" v-for="(slide, i) in slides" :key="i" class="swiper-slide">
          <div class="slide-content">
            <img :src="`/media/${slide.id}`" class="image" draggable="false">
          </div>
        </article>
      </div>
    </div>
    <div v-show="slides.length > 1" class="pagination">
      <button v-for="(slide, index) in slides" :key="index" :class="{ active: currentIndex === index }" @click="slideTo(index)" class="bullet"></button>
    </div>
    <v-fade-transition mode="out-in">
      <button @click.stop="prevSlide()" v-show="slides.length > 1 && !prevDisabled && $vuetify.breakpoint.mdAndUp" type="button" class="controls prev">
        <Lottie class="arrow" :options="{animationData:arrowJson, loop:true}" />
      </button>
    </v-fade-transition>
    <v-fade-transition mode="out-in">
      <button @click.stop="nextSlide()" v-show="slides.length > 1 && !nextDisabled && $vuetify.breakpoint.mdAndUp" type="button" class="controls next">
        <Lottie class="arrow" :options="{animationData:arrowJson, loop:true}" />
      </button>
    </v-fade-transition>
  </section>
</template>

<script>
import Lottie from 'vue-lottie'
import arrowJson from '@/assets/bodymovin/arrow-white.json'

import { ImageLoader } from 'three'
import { gsap } from 'gsap'

import { Swiper } from 'swiper'
import 'swiper/swiper-bundle.min.css'

const imageLoader = new ImageLoader()

const map = function (n, min1, max1, min2, max2) {
  return ((n - min1) * (max2 - min2)) / (max1 - min1) + min2
}

const cap = function (n, min, max) {
  return Math.min(Math.max(n, min), max)
}

export default {
  name: 'Gallery',
  inheritAttrs: false,
  components: { Lottie },
  props: {
    slides: { type: Array, required: true }
  },
  data () {
    return {
      arrowJson,
      prevDisabled: true,
      nextDisabled: false,
      currentIndex: 0
    }
  },
  beforeDestroy () {
    this.swiper && this.swiper.destroy(true, false)
    if (this.tls.length) {
      this.tls.forEach((tl) => tl.kill())
      this.tls.length = 0
    }
  },
  async mounted () {
    await this.$nextTick()
    this.preloadImages()
    this.tls = []
    this.currentTransitionSpeed = 0
    this.swiper = new Swiper(this.$refs.swiper, {
      allowTouchMove: this.slides.length > 1,
      centeredSlides: true,
      initialSlide: 0,
      touchStartPreventDefault: false,
      watchSlidesProgress: true,
      preventClicks: false,
      grabCursor: this.slides.length > 1,
      virtualTranslate: true,
      speed: 0,
      slidesPerView: 1,
      on: {
        init: this.setTranslate,
        setTranslate: this.setTranslate,
        activeIndexChange: (swiper) => {
          this.currentIndex = swiper.realIndex
        },
        reachBeginning: () => {
          this.prevDisabled = true
          this.nextDisabled = false
        },
        reachEnd: () => {
          this.prevDisabled = false
          this.nextDisabled = true
        },
        fromEdge: this.fromEdge
      }
    })
  },
  methods: {
    preloadImages () {
      this.slides.forEach((slide, i) => {
        imageLoader.load(`/media/${slide.id}`)
      })
    },
    setTranslate (swiper, wrapperTranslate) {
      if (wrapperTranslate === undefined) return
      const duration = cap(
        map(
          Math.abs(wrapperTranslate - swiper.previousTranslate),
          0,
          window.innerWidth,
          0,
          0.75
        ),
        0.2,
        0.75
      )
      if (this.tls.length) {
        this.tls.forEach((tl) => tl.kill())
        this.tls.length = 0
      }
      this.tls.push(
        gsap.to(this.$refs.swiperWrapper, {
          duration,
          x: -cap(
            -wrapperTranslate,
            swiper.snapGrid[0],
            swiper.snapGrid[swiper.snapGrid.length - 1]
          ),
          ease: 'Power3.out'
        })
      )
      // do magic with each slide
      swiper.slides.forEach((slide, i) => {
        const normProgress = Math.abs(map(cap(slide.progress, -2, 2), -2, 2, -1, 1))

        this.tls.push(gsap.to(this.$refs.slides[i], {
          duration,
          // scale: 1 - normProgress * 0.8,
          blur: 4 * normProgress,
          ease: 'Power3.out'
        }))
      })
    },
    fromEdge (swiper) {
      if (!swiper.isBeginning && !swiper.isEnd) {
        this.prevDisabled = false
        this.nextDisabled = false
      }
    },
    prevSlide () {
      this.swiper && this.swiper.slidePrev()
    },
    nextSlide () {
      this.swiper && this.swiper.slideNext()
    },
    slideTo (index) {
      this.swiper && this.swiper.slideTo(index)
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
}

.swiper-container {
  width: 100%;
  height: 44vh;
}

.swiper-slide {
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-out;

  &:not(.swiper-slide-active) {
    opacity: 0.4;
  }
}

.slide-content {
  height: 100%;
  pointer-events: none;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
}

.controls {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);

  &.prev {
    left: -50px;
    transform: translateY(-50%) scaleX(-1);
  }

  &.next {
    right: -50px;
  }
}

.pagination {
  position: relative;
  width: 100%;
  margin-top: 12px;

  .bullet {
    opacity: 0.4;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: $c-white;
    transition: opacity 0.2s;

    &.active {
      opacity: 1;
    }
  }
}

</style>
