<template>
  <div>
    <img
      v-for="(frame, index) in data"
      :key="frame.image.id + index"
      :src="'/media/' + frame.image.id"
      :class="[ frame.position ]"
      class="image"
    >
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  position: fixed;
  z-index: $z-overlay-frame;
  width: 50%;
  height: auto;
  pointer-events: none;

  @include breakpoint ('sm-and-down') {
    z-index: 9;
  }

  &.top-mobile ~ .top-left,
  &.top-mobile ~ .top-right {
    @include breakpoint ('sm-and-down') {
      display: none;
    }
  }

  &.top-mobile {
    top: 0;
    left: 0;
    width: 100%;
    display: none;

    @include breakpoint ('sm-and-down') {
      display: block;
    }
  }

  &.left {
    top: 0;
    left: 0;
    width: auto;
    height: 100%;

    @include breakpoint ('sm-and-down') {
      display: none;
    }
  }

  &.right {
    top: 0;
    right: 0;
    width: auto;
    height: 100%;

    @include breakpoint ('sm-and-down') {
      display: none;
    }
  }

  &.top-left {
    top: 0;
    left: 0;
  }

  &.top-right {
    top: 0;
    right: 0;
  }

  &.bottom-mobile ~ .bottom-left,
  &.bottom-mobile ~ .bottom-right {
    @include breakpoint ('sm-and-down') {
      display: none;
    }
  }

  &.bottom-mobile {
    right: 0;
    bottom: 56px;
    width: 100%;
    display: none;

    @include breakpoint ('sm-and-down') {
      display: block;
    }
  }

  &.bottom-left {
    bottom: 0;
    left: 0;

    @include breakpoint ('sm-and-down') {
      display: none;
    }
  }

  &.bottom-right {
    bottom: 0;
    right: 0;

    @include breakpoint ('sm-and-down') {
      display: none;
    }
  }
}
</style>
