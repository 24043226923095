var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"circle",class:[
    _vm.size,
    { 'bg-anim': _vm.bgAnim},
    { 'rollover-anim': _vm.rolloverAnim},
    { 'hole': _vm.icon && _vm.icon === 'keyhole'}
  ]},[_c('svg',{staticClass:"circlesvg",attrs:{"viewBox":"0 0 80 80"}},[_c('circle',{ref:"circlebg",staticClass:"circle-back",attrs:{"cx":"40","cy":"40","r":"30"}}),_vm._v(" "),_c('circle',{ref:"circle",staticClass:"circle-front",style:({
        strokeDashoffset: 200 - (200 * _vm.percentage),
      }),attrs:{"cx":"40","cy":"40","r":"30"}})]),_vm._v(" "),(_vm.bg)?_c('div',{staticClass:"bg absolute-center"}):_vm._e(),_vm._v(" "),(_vm.icon && _vm.icon === 'key')?_c('key',{staticClass:"icon absolute-center"}):_vm._e(),_vm._v(" "),(_vm.icon && _vm.icon === 'keyhole')?_c('keyhole',{staticClass:"icon absolute-center",style:({ opacity: 1 - _vm.percentage })}):_vm._e(),_vm._v(" "),(_vm.icon === 'circle')?_c('div',[_c('div',{staticClass:"outer-circle absolute-center",style:({ transform: ("translate(-50%, -50%) scale(" + _vm.percentage + ")") })}),_vm._v(" "),_c('div',{staticClass:"inner-circle absolute-center"})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }